@import 'core';

.AppCore {
    .SortableList {
        display: flex;

        &.vertical {
            flex-direction: column;
        }
        .SortableListItemWithAction,
        .SortableListItem {
            position: relative;
            touch-action: none;
            z-index: 1;
        }
        .SortableListItem {
            cursor: ew-resize;
        }

        &.vertical .SortableListItem {
            cursor: ns-resize;
        }

        .DraggingSortableListItem {
            color: inherit;
        }

        .DraggedSortableListItem {
            z-index: 2;
        }
    }
}
