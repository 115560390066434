@import 'core';

// TODO: use new colors after we have design for it
.AppCore {
    .ToggleButton {
        display: block;
        position: relative;
        .InputBaseWrapperInner {
            box-shadow: none;
        }

        &.InputBaseDisabledWrapper {
            pointer-events: none;
        }
        .Icon {
            color: $grey-dark-2 !important;
        }
        input[type='checkbox'] {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }

        .Button.tertiary {
            border-color: transparent;

            &.active,
            &:hover,
            &:focus {
                border-color: $grey-dark-2;
            }
        }

        &.ToggleButtonOn .Button.tertiary {
            border: 1px solid $grey-dark-1;

            .Icon {
                color: $grey-dark-1 !important;
            }
        }

        .Button.primary {
            background-color: $primary-blue-ligher;

            &:hover {
                background-color: $primary-blue;
            }
        }

        &.ToggleButtonOn .Button.primary {
            background-color: $primary-blue;

            &:hover {
                background-color: $primary-blue-active;
            }
        }
    }
}
