@import 'core';

.AppCore {
    .Notification {
        position: relative;
        padding: 11px 0;

        &.small {
            padding-top: 9px;
            padding-bottom: 8px;
        }

        &.xsmall {
            padding: 3px 0;
        }

        > .NotificationText {
            display: block;
            width: 100%;
            padding-left: 50px;
            padding-right: 45px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--content-primary);
            font-weight: $font-weight-medium;

            &.NoFixedHeight {
                height: auto;
                white-space: normal;
            }
        }

        > .NotificationIcon {
            position: absolute;
            top: 11px;
            left: 16px;
        }

        &.small > .NotificationIcon {
            top: 8px;
        }

        &.xsmall > .NotificationIcon {
            top: 3px;
            width: 20px;
            height: 20px;
            line-height: 20px;
        }

        > .NotificationAction {
            position: absolute;
            top: 13px;
            right: 17px;
            border-bottom: none;

            > .Icon {
                width: 20px;
                height: 20px;
                color: var(--content-tertiary);
            }
        }

        &.NoCloseAction {
            > .NotificationText {
                padding-right: 16px;
            }
        }

        &.error {
            border-color: var(--stroke-error-on-weak-fill);
            background: var(--fill-error-weak);
            .NotificationIcon {
                color: var(--content-error);
            }
            &.fill {
                border: 0;
                background: var(--fill-error);

                > .NotificationText {
                    color: var(--content-inverse);
                }
                .Icon {
                    color: var(--content-inverse-secondary);
                }
                .NotificationIcon {
                    color: var(--content-inverse);
                }
            }
        }
        &.success {
            border-color: var(--stroke-success-on-weak-fill);
            background: var(--fill-success-weak);
            .NotificationIcon {
                color: var(--content-success);
            }
            &.fill {
                border: 0;
                background: var(--fill-success);
                .Icon {
                    color: var(--content-secondary);
                }
                .NotificationIcon {
                    color: var(--content-success-on-week-fill);
                }
            }
        }
        &.warning {
            border-color: var(--stroke-warning-on-weak-fill);
            background: var(--fill-warning-weak);
            .NotificationIcon {
                color: var(--content-warning);
            }
            &.fill {
                border: 0;
                background: var(--fill-warning);
                .Icon {
                    color: var(--content-secondary);
                }
                .NotificationIcon {
                    color: var(--content-warning-on-week-fill);
                }
            }
        }
        &.info {
            border-color: var(--stroke-info-on-weak-fill);
            background: var(--fill-info-weak);
            .NotificationIcon {
                color: var(--content-info);
            }
            &.fill {
                border: 0;
                background: var(--fill-info);
                .Icon {
                    color: var(--content-secondary);
                }
                .NotificationIcon {
                    color: var(--content-info-on-week-fill);
                }
            }
        }
        &.comment {
            border-color: var(--stroke-accent);
            background: var(--fill-accent-weak);
            .NotificationIcon {
                color: var(--content-accent);
                top: 17px;
            }
            &.fill {
                border: 0;
                background: var(--fill-accent);
                > .NotificationText {
                    color: var(--content-inverse);
                }
                .Icon {
                    color: var(--content-inverse-secondary);
                }
                .NotificationIcon {
                    color: var(--content-inverse);
                }
            }
        }

        &.positioned {
            position: fixed;
        }

        &.position-top,
        &.position-top-left,
        &.position-top-right {
            top: 24px;
        }

        &.position-bottom,
        &.position-bottom-left,
        &.position-bottom-right {
            bottom: 24px;
        }

        &.position-top,
        &.position-bottom {
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }

        &.position-top-left,
        &.position-bottom-left {
            left: 24px;
        }

        &.position-top-right,
        &.position-bottom-right {
            right: 24px;
        }
    }

    .CustomNotification {
        > .NotificationText {
            padding: 0 16px;
            color: inherit;
        }

        .Icon.m {
            width: 22px;
            height: 22px;
            line-height: 22px;
        }

        .Button.xs {
            line-height: 22px;
        }
    }

    .InlineNotification {
        display: inline-block;
    }
}
