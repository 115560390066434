:root {
    --accent-100: #e3dafa;
    --accent-200: #e3dafa;
    --accent-300: #d0bbf7;
    --accent-400: #bd9cf1;
    --accent-500: #a16ae8;
    --accent-600: #944add;
    --accent-700: #6f2ea9;
    --accent-800: #5b288a;
    --accent-900: #39185d;

    --neutral-50: #fbfbfb;
    --neutral-60: #f2f6f8;
    --neutral-100: #f6f8f9;
    --neutral-150: #f6f8f9;
    --neutral-200: #ebeff3;
    --neutral-250: #ebeff3;
    --neutral-270: #e9e9fb;
    --neutral-300: #d4dce3;
    --neutral-310: #d4dce3;
    --neutral-350: #d4dce3;
    --neutral-400: #adbdcc;
    --neutral-450: #adbdcc;
    --neutral-500: #627d95;
    --neutral-600: #4d647c;
    --neutral-700: #504b59;
    --neutral-750: #3f5165;
    --neutral-800: #374655;
    --neutral-850: #374655;
    --neutral-900: #222932;
    --neutral-925: #222932;
    --neutral-950: #222932;

    --supplementary-1-100: #fdf2d7;
    --supplementary-1-200: #fbe1ad;
    --supplementary-1-300: #f9d28b;
    --supplementary-1-400: #f4ab43;
    --supplementary-1-500: #f1901e;
    --supplementary-1-600: #e27714;
    --supplementary-1-700: #bb5b13;
    --supplementary-1-800: #783c16;
    --supplementary-1-900: #411d09;

    --supplementary-2-100: #c9fef2;
    --supplementary-2-200: #94fbe5;
    --supplementary-2-300: #56f2d7;
    --supplementary-2-400: #1fccb3;
    --supplementary-2-500: #059c8b;
    --supplementary-2-600: #097c71;
    --supplementary-2-700: #0d625b;
    --supplementary-2-800: #10514c;
    --supplementary-2-900: #02312f;

    --supplementary-3-100: #dceffd;
    --supplementary-3-200: #c0e3fd;
    --supplementary-3-300: #95d3fb;
    --supplementary-3-400: #63b9f7;
    --supplementary-3-500: #3e9cf3;
    --supplementary-3-600: #297ee7;
    --supplementary-3-700: #1f64cc;
    --supplementary-3-800: #204988;
    --supplementary-3-900: #182e53;

    --supplementary-4-100: #ffe8fe;
    --supplementary-4-200: #fed0fb;
    --supplementary-4-300: #fbacf2;
    --supplementary-4-400: #f98bec;
    --supplementary-4-500: #ee47da;
    --supplementary-4-600: #d227ba;
    --supplementary-4-700: #8e1a7a;
    --supplementary-4-800: #741b63;
    --supplementary-4-900: #4d053e;

    --supplementary-5-100: #dae1ff;
    --supplementary-5-200: #bcc8ff;
    --supplementary-5-300: #93a3ff;
    --supplementary-5-400: #6871ff;
    --supplementary-5-500: #4845ff;
    --supplementary-5-600: #3c25ff;
    --supplementary-5-700: #3820e9;
    --supplementary-5-800: #261d92;
    --supplementary-5-900: #181155;

    --error-100: #fff2f1;
    --error-200: #ffccc6;
    --error-250: #ffccc6;
    --error-300: #ffa99f;
    --error-400: #ff7768;
    --error-500: #fb4b38;
    --error-600: #e93420;
    --error-700: #c42311;
    --error-800: #862116;
    --error-900: #490d06;

    --success-100: #d9ffe5;
    --success-200: #b5fdcc;
    --success-300: #7cf9a5;
    --success-400: #20e963;
    --success-500: #13d454;
    --success-600: #09b042;
    --success-700: #0b8a36;
    --success-800: #0e592a;
    --success-900: #013213;

    --warning-100: #faf6c7;
    --warning-200: #f6ec92;
    --warning-300: #f0da54;
    --warning-400: #e9c420;
    --warning-500: #daae18;
    --warning-600: #bc8812;
    --warning-700: #966212;
    --warning-800: #6a4019;
    --warning-900: #3e220a;

    --info-100: #e1f3fd;
    --info-200: #bde7fa;
    --info-300: #82d4f7;
    --info-400: #3fbff1;
    --info-500: #20b0e9;
    --info-600: #0987c0;
    --info-700: #096b9b;
    --info-800: #104b6a;
    --info-900: #0a3147;

    --accent-250: #e3dafa;
    --error-250: #ffccc6;
}
