$black: #000000;
$white: #ffffff;

$primary-green: #1fccb3;
$primary-green-light: #f1faf0;
$primary-blue: var(--content-accent);
$primary-blue-active: #1a4fa0;
$primary-blue-light: #42acd9;
$primary-blue-ligher: #98b7e6;
$primary-yellow: #f9d28b;
$primary-yellow-light: #faf8f0;
$primary-violet: #bd9cf1;
$primary-violet-light: #f7f0fa;

$violet-light: #e9e9fb;
$yellow-dark: #fdb827;

$grey-dark-1: #222932;
$grey-dark-2: #89969f;
$grey-dark-3: #b0bdc7;
$grey-dark-4: #434d5b;
$grey-dark-5: #12171c;
$grey-light-1: #cfd8df;
$grey-light-2: #e3e9ee;
$grey-light-3: #f2f6f8;
$grey-light-4: #f9f9fc;

$color-error: #cc1f51;
$color-warn: #f9d28b;
$color-success: #1fccb3;
$color-success-light: #7bcbbc;
$color-success-lighter: #cee3dd;

$color-error-light: #cc7d9e;
$color-error-lighter: #fff4fb;

$body-text: $grey-dark-1;
$body-elevated-background: #fbfbfb;
$breakpoints: (
    none: 0px,
    mobile: 500px,
    tablet: 760px,
    desktop: 1170px,
    xl: 1441px,
) !default;

$container-max-widths: (
    mobile: 100%,
    tablet: 100%,
    desktop: 1600px,
) !default;

$nav-width: 72px;
$nav-detailed-view-width: 186px;
$nav-sm-height: 64px;

$depth-below-main-sidebar: 2000;
$depth-main-sidebar: 3000;
$depth-above-main-sidebar: 4000;
$depth-above-everything: 9000;

// SHADOWS
$pop-up-overlay-shadow: var(--pop-up-overlay-x) var(--pop-up-overlay-y) var(--pop-up-overlay-blur) var(--pop-up-overlay-spread)
    var(--pop-up-overlay-color);
$eleveted-surface-shadow: var(--elevated-surface-x) var(--elevated-surface-y) var(--elevated-surface-blur) var(--elevated-surface-spread)
    var(--elevated-surface-color);
$table-header-shadow: var(--table-header-x) var(--table-header-y) var(--table-header-blur) var(--table-header-spread)
    var(--table-header-color);
$side-nav-shadow: var(--side-nav-x) var(--side-nav-y) var(--side-nav-blur) var(--side-nav-spread) var(--side-nav-color);

// TYPOGRAPHY
$primitive-sizes-128: 128px;
$primitive-sizes-96: 96px;
$primitive-sizes-72: 72px;
$primitive-sizes-56: 56px;
$primitive-sizes-44: 44px;
$primitive-sizes-40: 40px;
$primitive-sizes-36: 36px;
$primitive-sizes-32: 32px;
$primitive-sizes-28: 28px;
$primitive-sizes-24: 24px;
$primitive-sizes-20: 20px;
$primitive-sizes-18: 18px;
$primitive-sizes-16: 16px;
$primitive-sizes-14: 14px;
$primitive-sizes-12: 12px;

$font-size-display-1: $primitive-sizes-96;
$line-height-display-1: $primitive-sizes-128;
$font-size-display-2: $primitive-sizes-72;
$line-height-display-2: $primitive-sizes-96;
$font-size-display-3: $primitive-sizes-56;
$line-height-display-3: $primitive-sizes-72;

$font-size-heading-1: $primitive-sizes-36;
$line-height-heading-1: $primitive-sizes-44;

$font-size-heading-2: $primitive-sizes-32;
$line-height-heading-2: $primitive-sizes-40;

$font-size-heading-3: $primitive-sizes-28;
$line-height-heading-3: $primitive-sizes-36;

$font-size-heading-4: $primitive-sizes-24;
$line-height-heading-4: $primitive-sizes-32;

$font-size-heading-5: $primitive-sizes-20;
$line-height-heading-5: $primitive-sizes-28;

$font-size-heading-6: $primitive-sizes-16;
$line-height-heading-6: $primitive-sizes-24;

$font-size-body-md: $primitive-sizes-16;
$line-height-body-md: $primitive-sizes-24;

$font-size-body-sm: $primitive-sizes-14;
$line-height-body-sm: $primitive-sizes-20;

$font-size-body-xs: $primitive-sizes-12;
$line-height-body-xs: $primitive-sizes-20;

$font-size-body-lg: $primitive-sizes-18;
$line-height-body-lg: $primitive-sizes-24;

$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
