@import 'core';

.AppCore {
    .NotificationsDropdown {
        position: relative;
        width: 444px;
        min-height: 54px;
        padding: 0px;
        max-height: 380px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .NotificationsList {
            flex-grow: 2;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .NotificationFilters {
            display: flex;
            align-items: center;
            padding: 16px 12px 0px;
            margin-bottom: 16px;
        }
        .NotificationRow {
            padding: 6px 16px;
            > .Icon {
                color: var(--content-quaternary);
                flex-shrink: 0;
            }
            > .HeroBanner {
                width: 200px;
                height: 80px;
                margin: 8px 0 0 65px;
            }

            &.NoNotificationsRow {
                text-align: center;
                margin-bottom: 14px;
            }

            &.NotificationItem {
                display: flex;
                align-items: center;
                border-bottom: none;

                &:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                &.no-link {
                    cursor: default;
                }

                &:hover {
                    background-color: var(--fill-elevated-tertiary);
                    > .Icon {
                        color: var(--content-tertiary);
                    }
                }

                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                > .Icon {
                    width: 24px;
                    margin-right: 17px;
                }

                > div {
                    > span {
                        display: block;
                    }

                    > span:last-child {
                        margin-top: -5px;
                    }
                }
            }
        }

        .SeeAllAction {
            display: block;
            text-align: center;
            color: var(--content-secondary);
            margin-left: auto;
            height: 22px;
            border-bottom: 1px solid var(--content-secondary);
        }
    }
}
